import React, { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { CgArrowLongLeft, CgArrowLongRight } from "react-icons/cg";
import { FaChevronLeft } from "react-icons/fa6";
import { IoFilterOutline, IoFilterSharp } from "react-icons/io5";
import { RiDeleteBinLine } from "react-icons/ri";
import ReactPaginate from "react-paginate";
import axios from "../../axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Loader from "../../utils/Loader";

const TicketQueryTable = () => {
  const [allTickets, setAllTickets] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("");

  useEffect(() => {
    getAllTickets();
  }, []);

  const handleChatIdClick = (chatId) => {
    navigate(`/ticket-chats/${chatId}`);
  };

  const getAllTickets = (status) => {
    setIsLoading(true);
    let paramObj = {};
    if (status) {
      paramObj = {
        status,
      };
    }
    const url = `/qna/all-tickets`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
      params: paramObj,
    };

    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setAllTickets(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <div className="ticket_queries_outer_container">
        <div className="ticket_queries_header">
          <h2>
            <FaChevronLeft
              onClick={() => navigate(-1)}
              fontSize={18}
              style={{ cursor: "pointer" }}
            />{" "}
            Ticket Queries
          </h2>

          <div
            onClick={() => setShowFilter(true)}
            className="ticket_queries_header_right"
          >
            <span>
              <IoFilterSharp /> Filter
            </span>
            {showFilter && (
              <ul className="ticket_queries_header_right_dropdown">
                <li>
                  Pending
                  <input
                    type="radio"
                    onChange={() => {
                      getAllTickets("pending");
                      setStatus("pending");
                    }}
                    checked={status === "pending"}
                  />
                </li>
                <li>
                  closed
                  <input
                    type="radio"
                    onChange={() => {
                      getAllTickets("closed");
                      setStatus("closed");
                    }}
                    checked={status === "closed"}
                  />
                </li>
                <li>
                  open
                  <input
                    type="radio"
                    onChange={() => {
                      getAllTickets("open");
                      setStatus("open");
                    }}
                    checked={status === "open"}
                  />
                </li>
              </ul>
            )}
          </div>
        </div>
        <div className="ticket_queries_inner_container scrollbar-template">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <table>
                <thead>
                  <tr>
                    <th>
                      {/* <input type="checkbox" /> */}
                      Sr No.
                    </th>
                    <th>Name</th>
                    <th>Email ID</th>
                    <th>Ticket ID</th>
                    <th>Query</th>
                    <th>Created At</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {allTickets.map((data, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td
                          className="table_name cursor-pointer"
                          key={data._id}
                          onClick={() => handleChatIdClick(data._id)}
                        >
                          {data?.raisedBy?.username}
                        </td>
                        <td>{data?.raisedBy?.email}</td>
                        <td>#{data.ticketId}</td>
                        <td className="ticket_table_query">{data.query}</td>
                        <td>
                          {moment(data.createdAt).format("DD MM YYYY LT")}
                        </td>
                        <td>
                          <TicketQuerieDropdown data={data} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="operation_offer_paginate ticket_queries_paginate">
                <ReactPaginate
                  breakLabel="..."
                  previousLabel={
                    <span className="prev-button">
                      <CgArrowLongLeft />
                      Previous
                    </span>
                  }
                  nextLabel={
                    <span className="next-button">
                      Next
                      <CgArrowLongRight />
                    </span>
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default TicketQueryTable;

const TicketQuerieDropdown = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "closed":
        return "#28A745";

      case "pending":
        return "#FFA500";

      case "open":
        return "#007BFF";
      default:
        return "gray";
    }
  };

  return (
    <div
      className="dropdown"
      style={{ backgroundColor: getStatusColor(data.status) }}
    >
      <span className="dropdown-button">
        <span className="dot" />
        {data.status}
        {/* <BiChevronDown size={18} /> */}
      </span>
      {isOpen && (
        <div className="dropdown-content">
          {/* Your dropdown content here */}
          <li>
            Pending <input type="radio" />
          </li>
          <li>
            Answered <input type="radio" />
          </li>
          <li>
            Solved <input type="radio" />
          </li>
        </div>
      )}
    </div>
  );
};
