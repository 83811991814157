import React, { useState } from "react";
import { BsExclamationLg } from "react-icons/bs";
import { FaCheck } from "react-icons/fa6";
// import { ProgressBar, Step } from "react-step-progress-bar";

const EnrollStudentStepBar = ({
  step,
  setStep,
  isTelegram,
  isOnboarding,
  isremainder,
  isCertificate,
}) => {
  const [completeSteps, setCompleteSteps] = useState(true);

  return (
    <>
      <div className="enroll_student_step_bar">
        <div className="enroll_student_step_box_div">
          <div
            className={`enroll_student_step_box ${step === 1 && "active"}`}
            onClick={() => setStep(1)}
          >
            <div
              className={`enroll_student_step_box_content ${
                completeSteps && "active"
              }`}
            >
              {completeSteps ? <FaCheck /> : <BsExclamationLg fontSize={18} />}
            </div>
          </div>
          <p>Payment Page</p>
        </div>
        <div>
          <div className="enroll_student_step_box_div">
            <div
              className={`enroll_student_step_box ${step === 2 && "active"}`}
              onClick={() => setStep(2)}
            >
              <div
                className={`enroll_student_step_box_content ${
                  completeSteps && "active"
                }`}
              >
                {completeSteps ? (
                  <FaCheck />
                ) : (
                  <BsExclamationLg fontSize={18} />
                )}
              </div>
            </div>
            <p>Offer Letter</p>
          </div>
        </div>
        <div>
          <div className="enroll_student_step_box_div">
            <div
              className={`enroll_student_step_box ${step === 3 && "active"}`}
              onClick={() => setStep(3)}
            >
              <div
                className={`enroll_student_step_box_content ${
                  completeSteps && "active"
                }`}
              >
                {completeSteps ? (
                  <FaCheck />
                ) : (
                  <BsExclamationLg fontSize={18} />
                )}
              </div>
            </div>
            <p>Invoice</p>
          </div>
        </div>
        <div>
          <div className="enroll_student_step_box_div">
            <div
              className={`enroll_student_step_box ${step === 4 && "active"}`}
              onClick={() => setStep(4)}
            >
              <div
                className={`enroll_student_step_box_content ${
                  completeSteps && "active"
                }`}
              >
                {completeSteps ? (
                  <FaCheck />
                ) : (
                  <BsExclamationLg fontSize={18} />
                )}
              </div>
            </div>
            <p>Registration</p>
          </div>
        </div>
        <div>
          <div className="enroll_student_step_box_div">
            <div
              className={`enroll_student_step_box ${step === 5 && "active"}`}
              onClick={() => setStep(5)}
            >
              <div
                className={`enroll_student_step_box_content ${
                  isOnboarding && "active"
                }`}
              >
                {isOnboarding ? <FaCheck /> : <BsExclamationLg fontSize={18} />}
              </div>
            </div>
            <p>Onboarding</p>
          </div>
        </div>

        <div>
          <div className="enroll_student_step_box_div">
            <div
              className={`enroll_student_step_box ${step === 6 && "active"}`}
              onClick={() => setStep(6)}
            >
              <div
                className={`enroll_student_step_box_content ${
                  isremainder && "active"
                }`}
              >
                {isremainder ? <FaCheck /> : <BsExclamationLg fontSize={18} />}
              </div>
            </div>
            <p>Reminder</p>
          </div>
        </div>
        <div>
          <div className="enroll_student_step_box_div">
            <div
              className={`enroll_student_step_box ${step === 7 && "active"}`}
              onClick={() => setStep(7)}
            >
              <div
                className={`enroll_student_step_box_content ${
                  isTelegram && "active"
                }`}
              >
                {isTelegram ? <FaCheck /> : <BsExclamationLg fontSize={18} />}
              </div>
            </div>
            <p>Telegram Link</p>
          </div>
        </div>
        {/* <div>
          <div className="enroll_student_step_box_div">
            <div className="enroll_student_step_box">
              <div className="enroll_student_step_box_content">
                <BsExclamationLg fontSize={18} />
              </div>
            </div>
            <p>Final Reminder</p>
          </div>
        </div> */}
        <div>
          <div className="enroll_student_step_box_div">
            <div
              className={`enroll_student_step_box ${step === 8 && "active"}`}
              onClick={() => setStep(8)}
            >
              <div className="enroll_student_step_box_content">
                {/* <FaCheck /> */}
                <BsExclamationLg fontSize={18} />
              </div>
            </div>
            <p>Ticket</p>
          </div>
        </div>
        <div>
          <div className="enroll_student_step_box_div">
            <div
              className={`enroll_student_step_box ${step === 9 && "active"}`}
              onClick={() => setStep(9)}
            >
              <div
                className={`enroll_student_step_box_content ${
                  isCertificate && "active"
                }`}
              >
                {isCertificate ? (
                  <FaCheck />
                ) : (
                  <BsExclamationLg fontSize={18} />
                )}
              </div>
            </div>
            <p>Certificate</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnrollStudentStepBar;
