import React, { useEffect, useState } from "react";
import axios from "../../axios";
import moment from "moment/moment";
import Loader from "../../utils/Loader";
import { RiDeleteBin5Fill } from "react-icons/ri";
import {
  confirmToast,
  notifyDanger,
  notifySuccess,
} from "../../actions/customFn";
import { CiSearch } from "react-icons/ci";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import searchImage from "../../asset/images/search.png";

const MyleadTable = () => {
  const DUMMY_DATA = [
    {
      name: "Test User",
      email: "test@example.com",
      phone: "+92 1234567890",
    },
    {
      name: "Test User 2",
      email: "test2@example.com",
      phone: "+92 1234567890",
    },
    {
      name: "Test User 3",
      email: "test3@example.com",
      phone: "+92 1234567890",
    },
  ];

  // const DUMMY_DATA =[
  //   {
  //     name: "Test User",
  //     email: "test@example.com",
  //     phone: "+92 1234567890",
  //   }
  // ]

  // end

  const [leadData, setLeadData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [searchInp, setSearchInp] = useState("");
  const [isHover, setIsHover] = useState(false);


  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    const url = "leads/myleads";
    let paramObj = { disputed: false };
    const config = {
      params: paramObj,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    if (searchInp !== "") {
      paramObj.searchTerm = searchInp.trim();
    }
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setLeadData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [isUpdate]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setIsUpdate((p) => !p);
    }
  };

  const handleChange = (e) => {
    setSearchInp(e.target.value);
  };

  return (
    <>
      <div className="lead_table_searchbar">
        <div className="form_header">
          <BiChevronLeft onClick={() => navigate(-1)} size={35} />
          <h2>My Lead</h2>
        </div>
        {/* <div className="form_table_nav_input">
          <CiSearch
            onClick={() => setIsUpdate((p) => !p)}
            className="form_table_nav_input_search"
          />
          <input
            type="text"
            placeholder="Enter email or name"
            value={searchInp}
            onChange={(e) => setSearchInp(e.target.value)}
          />
        </div> */}
        <div
          className={`offer_letter_table_head_search_icon ${
            isHover ? "expanded" : ""
          }`}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(true)}
        >
          <img src={searchImage} alt="" className="oflettersearch" />
          {isHover && (
            <>
              <input
                value={searchInp}
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                type="text"
                placeholder="Search here..."
              />
              <img
                src={searchImage}
                onClick={() => setIsUpdate((p) => !p)}
                alt=""
                className="search_icon_svg"
              />
            </>
          )}
        </div>
      </div>
      <div className="module_student_table table-responsive scrollbar-template lead_table_container">
        {isLoading ? (
          <Loader Height={"40vh"} />
        ) : (
          <table className="table-template-style ">
            <thead>
              <tr>
                <th scope="col" className="table-first-element">
                  Sr no.
                </th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone no.</th>
                <th scope="col">Domain</th>
                <th scope="col">Created At</th>
                <th scope="col">Country</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {leadData.map((data, i) => {
                return (
                  <TableItem
                    data={data}
                    index={i}
                    setIsLoading={setIsLoading}
                    setIsUpdate={setIsUpdate}
                  />
                );
              })}
            </tbody>
          </table>
        )}

        {/* <h4 className="text-center text-black mt-4">No Students Found!</h4> */}
      </div>
      {/* {!isLoading && (
        <div className="react_paginate_Div">
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={totalPages}
            previousLabel="< previous"
            activeClassName={"active"}
            renderOnZeroPageCount={null}
            forcePage={currentPage !== 1 ? currentPage - 1 : ""}
          />
        </div>
      )} */}
    </>
  );
};

export default MyleadTable;

const TableItem = ({ index, data, setIsLoading, setIsUpdate }) => {
  const deleteLead = () => {
    const url = `leads/delete-lead/${data._id}`;
    setIsLoading(true);
    let paramObj = { disputed: false };
    const config = {
      params: paramObj,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .delete(url, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setIsUpdate((p) => !p);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        notifyDanger(err?.response?.data?.message || "Some Error is Occured");
      });
  };
  return (
    <tr key={index}>
      <td>{index + 1}</td>
      <td>{data.username}</td>
      <td>{data.email}</td>
      <td>{data.contact_no}</td>
      <td>{data.program_name}</td>
      <td>{moment(data.createdAt).format("DD MMM YYYY LT")}</td>
      <td>{data.country}</td>
      <td>
        <RiDeleteBin5Fill
          className="lead_delete_icon"
          onClick={() =>
            confirmToast("Are you want to delete this lead", deleteLead)
          }
        />
      </td>
    </tr>
  );
};
