import React, { useEffect, useState } from "react";
import { images } from "../../actions/customFn";
import { PiPlusBold } from "react-icons/pi";
import ComposeEmail from "./ComposeEmail";
import axios from "../../axios";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";

const EmailChatsList = ({ searchQuery }) => {
  const [toggle, setToggle] = useState(false);
  const [templateArr, setTemplateArr] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [allEmailList, setAllEmailList] = useState([]);
  const navigate = useNavigate();
  // console.log(allEmailList);

  const getAllTemplates = () => {
    const url = "/leads/get-all-email-templates";

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        // setIsLoading(false);
        // setIsUpdate((p) => !p);
        setTemplateArr(data);
      })
      .catch((err) => {
        console.log(err);
        // setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllTemplates();
  }, []);

  useEffect(() => {
    getAllEmailHistory();
  }, [isUpdate]);

  const getAllEmailHistory = () => {
    const url = "/leads/get-all-email-history";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setAllEmailList(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filterEmailList = allEmailList.filter((data) => {
    return (
      data.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
      data?.emailInfo?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      data?.batches?.some((itm) =>
        itm.batch_name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  });

  //

  



  return (
    <>
      <div className="email_chat_list_container">
        {filterEmailList?.length > 0 &&
          filterEmailList.map((data, i) => {
            return (
              <div
                key={i}
                style={{ backgroundColor: "#F5F5F5" }}
                className="email_chat_list"
                onClick={() => navigate(`/get-email/${data._id}`)}
              >
                <img src={images["batch_img.jpg"]} alt="" />
                <div className="email_chat_list_content">
                  <span>
                    {data.batches.map((itm, idx) => {
                      return <h3 className="active">{itm.batch_name}</h3>;
                    })}
                    <h3>{data.emailInfo}</h3>

                    <h6>{moment(data.createdAt).format("DD MMM YYYY LT")}</h6>
                  </span>
                  <p>{data.subject}</p>
                </div>
              </div>
            );
          })}
      </div>

      <div className="email_chat_cta">
        <PiPlusBold onClick={() => setToggle(!toggle)} />
      </div>
      {toggle && (
        <ComposeEmail
          setIsUpdate={setIsUpdate}
          templateArr={templateArr}
          toggle={toggle}
          setToggle={setToggle}
        />
      )}
    </>
  );
};

export default EmailChatsList;
