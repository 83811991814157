import React from "react";
import Sidebar from "./Sidebar";
import NewSidebar from "./NewSidebar";

const LayoutWrapper = ({ children }) => {
  
  return (
    <div className="row" style={{ height: "100vh" }}>
      <div className="col-2 new_sidebar_col">
        {/* <Sidebar /> */}
        <NewSidebar />
      </div>
      <div
        className="col-xl-10 col-12 p-0"
        style={{ background: "#ffff", height: "100vh" }}
      >
        {children}
      </div>
    </div>
  );
};

export default LayoutWrapper;
