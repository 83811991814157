import { useEffect } from "react";
import toast from "react-hot-toast";
import { Navigate, Route } from "react-router-dom";

const importAll = (r) => {
  let images = {};
  r.keys().map((item) => {
    return (images[item.replace("./", "")] = r(item));
  });
  return images;
};


const images = importAll(require.context("../asset/images", false));

const checkLogin = () => {
  var status = false;
  if (localStorage.getItem("oeson_auth") != null) {
    status = true;
  }
  return status;
};

const GetRoutes = (allRoutes) =>
  allRoutes.map((route) => {
    if (route.path) {
      return (
        <Route
          exact
          path={route.path}
          element={
            route.private === true ? (
              checkLogin() === true ? (
                route.page
              ) : (
                <Navigate to="/login" />
              )
            ) : (
              route.page
            )
          }
          key={route.key}
        />
      );
    }

    return null;
  });

const confirmToast = (text, func) => {
  toast((t) => (
    <div className="custom-toast">
      <h3>{text}</h3>
      <div className="custom-toast-div">
        <button
          className="toast-btn-yes"
          onClick={() => {
            func();
            toast.dismiss(t.id);
          }}
        >
          Yes{" "}
        </button>
        <button className="toast-btn-no" onClick={() => toast.dismiss(t.id)}>
          No
        </button>
      </div>
    </div>
  ));
};

function useOutsideClick(ref, setIsOpen) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const notifySuccess = (notification) => toast.success(notification);

const notifyDanger = (notification) => toast.error(notification);

const getFileExtension = (filename) => {
  const parts = filename.split(".");
  return parts[parts.length - 1].toLowerCase();
};

const handleScrollToError = (className) => {
  const element = document.querySelector(`.${className}`);
  if (element) {
    element?.previousElementSibling.scrollIntoView({ behavior: "smooth" });
  }
};

export {
  GetRoutes,
  images,
  notifySuccess,
  notifyDanger,
  confirmToast,
  useOutsideClick,
  getFileExtension,
  handleScrollToError,
};
