import React, { useState } from "react";
import { FaCaretDown, FaTrash } from "react-icons/fa6";
import { GoArrowLeft } from "react-icons/go";
import { IoIosShareAlt } from "react-icons/io";
import { images } from "../../actions/customFn";
import { MdArrowDropDown } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

const EmailChatsContent = ({ emailContent }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="email_chats_content">
        <div className="email_chats_content_top">
          <div className="email_chats_content_head">
            <GoArrowLeft
              onClick={() => navigate(-1)}
              className="arrow_left"
              size={20}
            />
            <h2>{emailContent.subject}</h2>
          </div>
        </div>

        <div className="email_chats_content_profile_container scrollbar-template">
          <div className="email_chats_content_profile">
            <div className="email_chats_content_profile_left">
              <img src={images["batch_img.jpg"]} alt="" />
              <div className="email_chats_content_mail">
                <h4>
                  {emailContent.batches?.map((itm, idx) => {
                    return <h3 className="active">{itm.batch_name}</h3>;
                  })}
                </h4>
                <span>{emailContent.emailInfo}</span>
                {/* <p>{emailContent.subject}</p> */}
                {/* <h5 onClick={() => setShowBatch(!showBatch)}>
                  to Batch names <MdArrowDropDown />
                  {showBatch && (
                    <div className="show_batch_modal_div">
                      <div className="show_batch_modal_content">
                        <h5>
                          From <span>:</span>
                        </h5>
                        <p>Oeson oeson@oeson.hr </p>
                      </div>
                      <div className="show_batch_modal_content">
                        <h5>
                          To <span>:</span>
                        </h5>
                        <p>Batch Names </p>
                      </div>
                      <div className="show_batch_modal_content">
                        <h5>
                          Date <span>:</span>
                        </h5>
                        <p>May 1, 2024, 10:39AM </p>
                      </div>
                      <div className="show_batch_modal_content">
                        <h5>
                          subject <span>:</span>
                        </h5>
                        <p>Ticket Request </p>
                      </div>
                    </div>
                  )}
                </h5> */}
              </div>
            </div>
            <div className="email_chats_content_forward">
              {/* <>9:14 AM (8 hours ago)</> */}
              <p>{moment(emailContent.createdAt).format("DD MMM YYYY LT")}</p>
            </div>
          </div>
          <p dangerouslySetInnerHTML={{ __html: emailContent.body }}></p>

          <div className="email_chats_content_underline"></div>
        </div>
      </div>
    </>
  );
};

export default EmailChatsContent;
