import React, { useEffect, useState } from "react";
import { BiCamera, BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import user from "../../asset/images/user.png";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncProfileData,
  asyncUpdateProfileDetails,
  asyncUpdateProfilePassword,
} from "../../actions/loginAction";

const StudentProfile = () => {
  const profileData = useSelector((state) => state.profileData.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    password: "",
    confirmPassword: "",
    newPassword: "",
  });

  const [img, setImg] = useState({
    file: "",
    preview: "",
  });

  const [isUpdate, setIsUpdate] = useState(false);

  const profileErrorObj = {};
  const [profileErrors, setProfileErrors] = useState({});

  const passwordErrorObj = {};
  const [passwordErrors, setPasswordErrors] = useState({});

  const handleDataChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleImgChange = (e) => {
    const { files } = e.target;
    if (files && files.length > 0) {
      setImg({
        file: files[0],
        preview: URL.createObjectURL(files[0]),
      });
    }
  };

  useEffect(() => {
    if (profileData && Object.keys(profileData).length > 0) {
      setUserData({
        name: profileData.username,
        email: profileData.email,
        phone: profileData.phone_no,
        address: profileData.address || "",
        password: "",
        confirmPassword: "",
        newPassword: "",
      });

      setImg({
        file: "",
        preview: profileData.image,
      });
    }
  }, [profileData]);

  const initialProfileState = () => {
    setUserData({
      name: "",
      address: "",
      email: "",
      phone_no: "",
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  const handleUpdateProfile = () => {
    profileValidation();
    if (Object.keys(profileErrorObj).length === 0) {
      setIsLoading(true);
      const formdata = new FormData();
      if (img.file !== "") {
        formdata.append("image", img.file);
      }
      formdata.append("username", userData.name);
      formdata.append("phone_no", userData.phone);
      formdata.append("address", userData.address);
      dispatch(
        asyncUpdateProfileDetails(formdata, initialProfileState, setIsUpdate)
      );
    }
  };

  useEffect(() => {
    dispatch(asyncProfileData(setIsLoading));
  }, [isUpdate]);

  const profileValidation = () => {
    if (userData.name.length === 0) {
      profileErrorObj.name = "Name is required!";
    }
    if (userData.address.length === 0) {
      profileErrorObj.address = "Address is required!";
    }
    if (userData.phone.length === 0) {
      profileErrorObj.phone = "Phone No. is required!";
    }
    setProfileErrors(profileErrorObj);
  };

  const passwordValidation = () => {
    const passwordRegex =
      /^(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{6,}$/;
    if (
      userData.confirmPassword.length > 0 &&
      userData.newPassword.length > 0 &&
      userData.confirmPassword !== userData.newPassword
    ) {
      passwordErrorObj.confirmPassword =
        "New password or confirm password not match ";
    } else if (!passwordRegex.test(userData.newPassword)) {
      passwordErrorObj.newPassword =
        "Password must contain a special character, number, capital letter, lowercase letter, and be at least 6 characters long";
    }

    if (userData.confirmPassword.length === 0) {
      passwordErrorObj.confirmPassword = "Confirm password is required!";
    }
    if (userData.newPassword.length === 0) {
      passwordErrorObj.newPassword = "New password is required!";
    }
    if (userData.password.length === 0) {
      passwordErrorObj.password = "current password is required!";
    }
    setPasswordErrors(passwordErrorObj);
  };

  const handleUpdatePassword = () => {
    passwordValidation();
    if (Object.keys(passwordErrorObj).length === 0) {
      setIsLoading(true);
      const data = {
        current_password: userData.password,
        confirm_password: userData.confirmPassword,
        new_password: userData.newPassword,
      };

      dispatch(
        asyncUpdateProfilePassword(data, initialProfileState, setIsUpdate)
      );
    }
  };

  return (
    <>
      <div className="enroll_student_profile_container">
        <div className="enroll_student_profile_header">
          <h2>
            <BiChevronLeft
              size={35}
              onClick={() => navigate(-1)}
              style={{ cursor: "pointer" }}
            />
            Profile
          </h2>
        </div>
        <div className="enroll_student_profile_details">
          <div className="enroll_student_profile_img_div">
            <label htmlFor="profileId">
              <img alt="" src={img.preview} key={img.preview} />
              <BiCamera />
            </label>
            <input
              type="file"
              id="profileId"
              accept="image/*"
              placeholder="Enter file"
              className="d-none"
              onChange={handleImgChange}
            />
          </div>
          <div className="enroll_student_profile_personal_details">
            <div className="row row-gap-3">
              <div className="col-md-6 enroll_student_profile_input_div">
                <label>User Name</label>
                <input
                  type="text"
                  placeholder="Enter name"
                  value={userData.name}
                  name="name"
                  onChange={handleDataChange}
                />
                {profileErrors.name && (
                  <div className="form_error_div">{profileErrors.name}</div>
                )}
              </div>
              <div className="col-md-6 enroll_student_profile_input_div">
                <label>Email Address</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter Email"
                  disabled={true}
                  value={userData.email}
                  onChange={handleDataChange}
                />
              </div>
              <div className="col-md-6 enroll_student_profile_input_div">
                <label>Phone Number</label>
                <input
                  type="number"
                  placeholder="Enter Phone No."
                  name="phone"
                  value={userData.phone}
                  onChange={handleDataChange}
                />
                {profileErrors.phone && (
                  <div className="form_error_div">{profileErrors.phone}</div>
                )}
              </div>
              <div className="col-md-6 enroll_student_profile_input_div">
                <label>Address</label>
                <input
                  type="text"
                  placeholder="Enter Address"
                  name="address"
                  value={userData.address}
                  onChange={handleDataChange}
                />
                {profileErrors.address && (
                  <div className="form_error_div">{profileErrors.address}</div>
                )}
              </div>
            </div>
            <button onClick={() => handleUpdateProfile()}>
              Update Details
            </button>
          </div>
          <div className="enroll_student_profile_personal_details">
            <div className="row row-gap-3">
              <div className="col-12 enroll_student_profile_input_div">
                <label>Current Password</label>
                <input
                  type="text"
                  placeholder="enter current password"
                  value={userData.password}
                  name="password"
                  onChange={handleDataChange}
                />
                {passwordErrors.password && (
                  <div className="form_error_div">
                    {passwordErrors.password}
                  </div>
                )}
              </div>
              <div className="col-md-6 enroll_student_profile_input_div">
                <label>New Password</label>
                <input
                  type="text"
                  placeholder="enter new password"
                  name="newPassword"
                  value={userData.newPassword}
                  onChange={handleDataChange}
                />
                {passwordErrors.newPassword && (
                  <div className="form_error_div">
                    {passwordErrors.newPassword}
                  </div>
                )}
              </div>
              <div className="col-md-6 enroll_student_profile_input_div">
                <label>Confirm Password</label>
                <input
                  type="text"
                  placeholder="enter confirm password"
                  name="confirmPassword"
                  value={userData.confirmPassword}
                  onChange={handleDataChange}
                />
                {passwordErrors.confirmPassword && (
                  <div className="form_error_div">
                    {passwordErrors.confirmPassword}
                  </div>
                )}
              </div>
            </div>
            <button onClick={() => handleUpdatePassword()}>
              Update Password
            </button>
          </div>
        </div>
      </div>
      {/*  */}
      
    </>
  );
};

export default StudentProfile;
