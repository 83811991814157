import React, { useContext, useEffect, useState } from "react";
import axios from "../../axios";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import Loader from "../../utils/Loader";
import { FormContext } from "../../pages/FormTable";
import { NavLink } from "react-router-dom";
import { CgArrowLongLeft, CgArrowLongRight } from "react-icons/cg";
const EnrolldataTable = ({ searchInp, isUpdate, rowsLimit }) => {
  const DUMMY_DATA = [
    {
      name: "Test User",
      email: "test@example.com",
      phone: "+92 1234567890",
    },
    {
      name: "Test User 2",
      email: "test2@example.com",
      phone: "+92 1234567890",
    },
    {
      name: "Test User 3",
      email: "test3@example.com",
      phone: "+92 1234567890",
    },
  ];

  const [enrollData, setEnrollData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const { filterOptions } = useContext(FormContext);

  useEffect(() => {
    setIsLoading(true);
    const url = `/user/enroll-users`;

    let paramObj = {
      page: currentPage,
    };

    if (searchInp !== "") {
      paramObj.searchTerm = searchInp.trim();
    }

    if (rowsLimit) {
      paramObj.limit = rowsLimit;
    } else {
      paramObj.limit = 10;
    }

    // if (selectedDate !== "") {
    //   paramObj.date = selectedDate;
    // }
    // if (selectedProgram !== "") {
    //   paramObj.program = selectedProgram;
    // }

    paramObj = { ...paramObj, ...filterOptions };
    const config = {
      params: paramObj,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data, totalPages, totalCount } = res.data;
        setTotalPages(totalPages);
        setEnrollData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err.response);
      });
  }, [currentPage, isUpdate]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [isUpdate]);

  return (
    <>
      <div className="module_student_table table-responsive scrollbar-template">
        {isLoading ? (
          <Loader Height={"40vh"} />
        ) : (
          <table className="table-template-style ">
            <thead>
              <tr>
                <th scope="col" className="table-first-element">
                  Sr no.
                </th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone no.</th>
                <th scope="col">Country</th>
                <th scope="col">Enroll At</th>
                <th scope="col">Batch</th>
                <th scope="col">InternCode</th>
                <th scope="col">Program</th>
                <th scope="col" style={{ minWidth: "100px" }}>
                  Gender
                </th>
                <th scope="col">Address</th>
                <th scope="col">Enrolled Batches</th>
              </tr>
            </thead>
            <tbody>
              {enrollData.map((data, i) => {
                return (
                  <TableItem
                    data={data}
                    index={i}
                    currentPage={currentPage}
                    rowsLimit={rowsLimit}
                  />
                );
              })}
            </tbody>
          </table>
        )}

        {/* <h4 className="text-center text-black mt-4">No Students Found!</h4> */}
      </div>
      {!isLoading && (
        <div className="react_paginate_Div">
          <ReactPaginate
            breakLabel="..."
            // nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={totalPages}
            // previousLabel="< previous"
            activeClassName={"active"}
            renderOnZeroPageCount={null}
            forcePage={currentPage !== 1 ? currentPage - 1 : ""}
            previousLabel={
              <span className="prev-button">
                <CgArrowLongLeft />
                Previous
              </span>
            }
            nextLabel={
              <span className="next-button">
                Next
                <CgArrowLongRight />
              </span>
            }
          />
        </div>
      )}
    </>
  );
};

export default EnrolldataTable;

const TableItem = ({ index, data, currentPage, rowsLimit }) => {
  return (
    <tr key={index}>
      <td>{(currentPage - 1) * rowsLimit + index + 1}</td>
      <td className="table_navlibk_td">
        <NavLink to={`/forms/summary/${data._id}`}>{data.username}</NavLink>
      </td>
      <td>{data.email}</td>
      <td>{data.contact_no}</td>
      <td>{data.country}</td>
      <td>{moment(data.createdAt).format("DD MMM YYYY LT")}</td>
      <td>
        {data?.cohort_details?.date
          ? moment(data.cohort_details.date).format("DD MMM YYYY ") + " "
          : ""}
        {data?.cohort_details?.week
          ? `(${data.cohort_details.week})`
          : "not available"}{" "}
        {data?.cohort_details?.time ? data.cohort_details.time : ""}
      </td>
      <td>{data.intern_code}</td>
      <td>{data.program_name}</td>
      <td>{data.gender ? data.gender : "not available"}</td>
      <td>{data.address}</td>
      <td>
        {data.enroll_batches?.length > 0 ? (
          data.enroll_batches.map((data, i) => {
            return (
              <b key={i}>
                {data.batch_name}
                <br />
              </b>
            );
          })
        ) : (
          <b>No Batch Found!</b>
        )}
      </td>
    </tr>
  );
};
