import React from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import StudentProfile from "../components/Profile/StudentProfile";

const Profile = () => {
  return (
    <>
      <LayoutWrapper>
        <StudentProfile />
      </LayoutWrapper>
    </>
  );
};

export default Profile;
