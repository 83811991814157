import React, { createContext, useEffect, useState } from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import EnrollStudentTable from "../components/EnrollStudentData/EnrollStudentTable";

export const EnrollContext = createContext();

const EnrollStudent = () => {
  const [isUpdate, setIsUpdate] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});
  const [rowsLimit, setRowsLimit] = useState(12);
  useEffect(() => {
    setIsUpdate((p) => !p);
  }, [filterOptions, rowsLimit]);
  console.log(filterOptions);

  return (
    <EnrollContext.Provider value={{ filterOptions, setFilterOptions }}>
      <LayoutWrapper>
        {/* <Header /> */}
        <EnrollStudentTable
          isUpdate={isUpdate}
          setIsUpdate={setIsUpdate}
          rowsLimit={rowsLimit}
          setRowsLimit={setRowsLimit}
        />
      </LayoutWrapper>
    </EnrollContext.Provider>
  );
};

export default EnrollStudent;
