import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import EnrollStudentStepBar from "../components/EnrollStudentData/EnrollStudentStepBar";
import EnrollStudentHeader from "../components/EnrollStudentData/EnrollStudentHeader";
import EnrollStudentPaymentTable from "../components/EnrollStudentData/EnrollStudentPaymentTable";
import SendOfferLetterForm from "../components/SendOfferLetter/SendOfferLetterForm";
import EnrollStudentOfferLetter from "../components/EnrollStudentData/EnrollStudentOfferLetter";
import EnrollStudentInvoice from "../components/EnrollStudentData/EnrollStudentInvoice";
import EnrollStudentRegister from "../components/EnrollStudentData/EnrollStudentRegister";
import EnrollStudentRemind from "../components/EnrollStudentData/EnrollStudentRemind";
import EnrollStudentTicket from "../components/EnrollStudentData/EnrollStudentTicket";
import EnrollStudentCertificate from "../components/EnrollStudentData/EnrollStudentCertificate";
import EnrollStudentCertificateForm from "../components/EnrollStudentData/EnrollStudentCertificateForm";
import { useParams } from "react-router-dom";
import axios from "../axios";
import { notifyDanger, notifySuccess } from "../actions/customFn";

const EnrollStudentDetails = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [step, setStep] = useState(1);
  const [subject, setSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [currentBatchDetails, setCurrentBatchDetails] = useState({});
  const [template, setTemplate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentUserId, setCurrentUserId] = useState("");

  const [isOnboarding, setIsOnboarding] = useState(false);
  const [isremainder, setIsRemainder] = useState(false);
  const [isTelegram, setIsTelegram] = useState(false);

  const [certificateData, setCertificateData] = useState({});

  const [date, setDate] = useState({
    start: "",
    end: "",
  });

  const [certificateList, setCertificateList] = useState([]);

  const [isCertificate, setIsCertificate] = useState(false);

  const [certificateInfo, setCertificateInfo] = useState({});

  const [isCertificateUpdate, setIsCertificateUpdate] = useState(false);

  useEffect(() => {
    const url = `/leads/get-student/${id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setFormData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [isUpdate]);

  useEffect(() => {
    let templateId = "";
    if (step === 4) {
      templateId = "6727c88b9a1c9e51fe412350";
    } else if (step === 5) {
      templateId = "671203dc30b7b5bc11b077ac";
    } else if (step === 6) {
      templateId = "672086be341328e342a3d8ff";
    } else if (step === 7) {
      templateId = "67208736341328e342a3da80";
    } else {
      templateId = "";
    }
    setTemplate(templateId);
    const url = `/leads/get-email-template/${templateId}`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setSubject(data.template_subject);
        setEmailBody(data.template_body);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [step]);

  const getCertificateFormatDate = (date) => {
    const newDate = new Date(date);
    let dayandmonth = newDate.toLocaleDateString("en-GB", {
      month: "long",
      day: "2-digit",
    });
    const formatDate =
      dayandmonth.split(" ").reverse().join(" ").toString() +
      ", " +
      newDate.getFullYear();
    return formatDate;
  };

  useEffect(() => {
    if (formData && Object.keys(formData).length > 0) {
      const url = `/leads/user-details`;
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
        },
        params: {
          email: formData.email,
        },
      };
      axios
        .get(url, config)
        .then((res) => {
          const { data } = res.data;
          const programName = formData.program_name;
          const currentBatch = data.batch_enroll.find(
            (val) => val?.courseInfo?.title === programName
          );
          setCurrentBatchDetails(currentBatch);
          setCurrentUserId(data._id);

          setCertificateData({
            email: data.email,
            name: data.username,
            internCode: data.intern_code,
            gender: data.gender,
            program: currentBatch.courseInfo?.title,
            batchId: currentBatch._id,
            userId: data._id,
          });
          let endDate = new Date(currentBatch.batch_start).setDate(
            new Date(currentBatch.batch_start).getDate() + 90
          );
          const formatStartdate = getCertificateFormatDate(
            currentBatch.batch_start
          );
          const formatenddate = getCertificateFormatDate(endDate);
          setDate({
            start: formatStartdate,
            end: formatenddate,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [formData]);

  const sendEmail = () => {
    setIsLoading(true);
    const url = `/leads/template/send-email`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    const data = {
      template,
      email: formData.email,
      subject,
      body: emailBody,
    };

    axios
      .put(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setIsLoading(false);
        setIsUpdate((p) => !p);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const checkOnboarding = () => {
    const url = `/leads/template/check-email`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
      params: {
        email: formData.email,
        template: "671203dc30b7b5bc11b077ac",
        batchId: currentBatchDetails._id,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setIsOnboarding(data.isExist);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkRemainder = () => {
    const url = `/leads/template/check-email`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
      params: {
        email: formData.email,
        template: "672086be341328e342a3d8ff",
        batchId: currentBatchDetails._id,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setIsRemainder(data.isExist);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkTelegram = () => {
    const url = `/leads/template/check-email`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
      params: {
        email: formData.email,
        template: "67208736341328e342a3da80",
        batchId: currentBatchDetails._id,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setIsTelegram(data.isExist);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // 671203dc30b7b5bc11b077ac
    // 672086be341328e342a3d8ff
    // 67208736341328e342a3da80
    checkTelegram();
    checkRemainder();
    checkOnboarding();
  }, [isUpdate]);

  const getCertificateData = () => {
    const url = `/batch/certificate-data/${currentBatchDetails._id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setCertificateList(data);
      })
      .catch((err) => {
        console.log(err);
        notifyDanger(err.response.data.message || "Some Error Occured!");
      });
  };

  useEffect(() => {
    if (currentBatchDetails && currentBatchDetails._id) {
      getCertificateData();
    }
  }, [currentBatchDetails, isCertificateUpdate]);

  useEffect(() => {
    if (
      certificateData &&
      certificateList &&
      certificateList?.student_details?.length > 0
    ) {
      const isExist = certificateList?.student_details.find((val) => {
        return val.user === certificateData.userId;
      });
      setIsCertificate(isExist ? true : false);
      setCertificateInfo(isExist);
    }
  }, [certificateData, certificateList]);

  return (
    <>
      <LayoutWrapper>
        {/* <Header /> */}
        <div className="enroll_student_details_container">
          <EnrollStudentHeader formData={formData} />
          <EnrollStudentStepBar
            step={step}
            setStep={setStep}
            isTelegram={isTelegram}
            isOnboarding={isOnboarding}
            isremainder={isremainder}
            isCertificate={isCertificate}
          />
          {step === 1 && <EnrollStudentPaymentTable formData={formData} />}
          {step === 2 && <SendOfferLetterForm formData={formData} />}
          {step === 3 && <EnrollStudentInvoice formData={formData} />}
          {(step === 4 || step === 5 || step === 6 || step === 7) && (
            <EnrollStudentOfferLetter
              formData={formData}
              subject={subject}
              setSubject={setSubject}
              emailBody={emailBody}
              setEmailBody={setEmailBody}
              isLoading={isLoading}
              sendEmail={sendEmail}
            />
          )}

          {/* <EnrollStudentRegister /> */}
          {/* <EnrollStudentRemind /> */}
          {/* <EnrollStudentTelegram /> */}
          {step === 8 && <EnrollStudentTicket currentUserId={currentUserId} />}

          {step === 9 &&
            (isCertificate ? (
              <EnrollStudentCertificate
                certificateInfo={certificateInfo}
                certificateData={certificateData}
                date={date}
                setIsCertificateUpdate={setIsCertificateUpdate}
              />
            ) : (
              <div className="d-flex justify-content-center">
                <EnrollStudentCertificateForm
                  certificateData={certificateData}
                  date={date}
                  certificateInfo={certificateInfo}
                  setIsCertificateUpdate={setIsCertificateUpdate}
                />
              </div>
            ))}
        </div>
      </LayoutWrapper>
    </>
  );
};

export default EnrollStudentDetails;
