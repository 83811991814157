import React, { useContext, useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import { CiFilter } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";
import { CiCalendar } from "react-icons/ci";
import { TfiAngleDown } from "react-icons/tfi";
import ReactSelect from "react-select";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useOutsideClick } from "../../actions/customFn";
import axios from "../../axios";
import moment from "moment";
import { FormContext } from "../../pages/FormTable";
import { motion } from "framer-motion";
import { BiChevronLeft } from "react-icons/bi";
import filter from "../../asset/images/filter.png";
import filter_lines from "../../asset/images/filter-lines.png";
import searchImage from "../../asset/images/search.png";



const FormTableNavs = ({
  searchInp,
  setSearchInp,
  setIsUpdate,
  setRowsLimit,
  rowsLimit,
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isRowOpen, setIsRowOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setIsUpdate((p) => !p);
    }
  };


  const handleChange = (e) => {
    setSearchInp(e.target.value);
  };

  return (
    <div className="form_table_navs_container">
      {/* <div className="form_table_navs">
        <NavLink to={"/forms/enquiry-data"}>Program Enquiry</NavLink>
        <NavLink to={"/forms/payment-data"}>Payment</NavLink>
        <NavLink to={"/forms/ogtip-data"}>OGTIP (Request)</NavLink>
        <NavLink to={"/forms/contactus-data"}>Contact Us</NavLink>
        <NavLink to={"/forms/enroll-data"}>Enroll Data</NavLink>
        <NavLink to={"/forms/invoice-data"}>Invoice Data </NavLink>
      </div> */}
      <div className="form_header">
        <BiChevronLeft onClick={() => navigate(-1)} size={35} />

        {pathname === "/forms/enroll-data" && <h2>Offer Letter</h2>}
        {pathname === "/forms/payment-data" && <h2>Payment Data</h2>}
        {pathname === "/forms/invoice-data" && <h2>Invoice Data</h2>}
        {pathname === "/forms/ogtip-data" && <h2>OGTIP Data</h2>}
        {pathname === "/forms/enquiry-data" && <h2>Enquiry Data</h2>}
        {pathname === "/forms/brochure-data" && <h2>Brochure Data</h2>}
      </div>
      <div className="form_table_nav_search">
        <div
          className={`offer_letter_table_head_search_icon ${
            isHover ? "expanded" : ""
          }`}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(true)}
        >
          <img src={searchImage} alt="" className="oflettersearch" />
          {isHover && (
            <>
              <input
                value={searchInp}
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                type="text"
                placeholder="Search here..."
              />
              <img
                src={searchImage}
                onClick={() => setIsUpdate((p) => !p)}
                alt=""
                className="search_icon_svg"
              />
            </>
          )}
        </div>
        {/* <div className="form_table_nav_input">
          <CiSearch
            onClick={() => setIsUpdate((p) => !p)}
            className="form_table_nav_input_search"
          />
          <input
            type="text"
            placeholder="Search"
            value={searchInp}
            onChange={(e) => setSearchInp(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div> */}

        {/* <div className="form_table_nav_filter">
          <button onClick={() => setIsFilterOpen(true)}>
            <CiFilter />
            <span>Filter </span>
          </button>
        </div> */}
        <div className="form_table_nav_filter">
          <img onClick={() => setIsFilterOpen(true)} src={filter} alt="" />
        </div>

        {/* <div className="form_table_nav_page">
          <select
            value={rowsLimit}
            onChange={(e) => {
              setRowsLimit(e.target.value);
            }}
          >
            <option value={""}>Select Page</option>
            <option value={10}>10 Rows</option>
            <option value={50}>50 Rows</option>
            <option value={100}>100 Rows</option>
          </select>
        </div> */}

        <div
          onClick={() => setIsRowOpen(!isRowOpen)}
          className="offer_letter_table_head_icon"
        >
          <img src={filter_lines} alt="" />
          {isRowOpen && (
            <div className="rows_dropdown_list">
              <span className={`${rowsLimit === "20" && "active"}`}>
                <input
                  type="radio"
                  value="20"
                  name="rows"
                  onChange={(e) => setRowsLimit(e.target.value)}
                />
                <label>20 Rows</label>
              </span>
              <span className={`${rowsLimit === "50" && "active"}`}>
                <input
                  type="radio"
                  value="50"
                  name="rows"
                  onChange={(e) => setRowsLimit(e.target.value)}
                />
                <label>50 Rows</label>
              </span>
              <span className={`${rowsLimit === "100" && "active"}`}>
                <input
                  type="radio"
                  value="100"
                  name="rows"
                  onChange={(e) => setRowsLimit(e.target.value)}
                />
                <label>100 Rows</label>
              </span>
            </div>
          )}
        </div>
      </div>
      <FilterOptions
        setIsFilterOpen={setIsFilterOpen}
        isFilterOpen={isFilterOpen}
      />
    </div>
  );
};

export default FormTableNavs;

const Dummy_Data = [
  {
    course: "Data Science",
  },
  {
    course: "Digital Marketing",
  },
  {
    course: "Cyber Security",
  },
  {
    course: "Business Analytics",
  },
  {
    course: "Data Analytics",
  },
  {
    course: "Data Engineering",
  },
  {
    course: "Project Management",
  },
  {
    course: "DevOps Engineer",
  },
];

const FilterOptions = ({ setIsFilterOpen, isFilterOpen }) => {
  const [selectedDate, setSelectedDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [isClearable, setIsClearable] = useState(false);

  const handleSelectDate = (ranges) => {
    // setSelectedDate([ranges.selection]);
    const adjustedEndDate = new Date(ranges.selection.endDate);
    adjustedEndDate.setHours(23, 59, 59, 999);
    setSelectedDate([
      {
        ...ranges.selection,
        endDate: adjustedEndDate,
      },
    ]);
  };

  const [isCalendar, setIsCalendar] = useState(false);
  const filterRef = useRef(null);
  useOutsideClick(filterRef, setIsFilterOpen);
  const [batches, setBatches] = useState([]);

  const [countries, setCountries] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);

  const [isBatch, setIsBatch] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState("");
  const { pathname } = useLocation();

  const [isFilterCheck, setIsFilterCheck] = useState(false);

  const { setFilterOptions } = useContext(FormContext);

  const getPaymentFilterOptions = () => {
    const url = "/payment/filter-options";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        const batches = data.allBatches.map((date) => {
          return { batch: date, isCheck: false };
        });
        setBatches(batches);
        const options = data.allcountries.map((country) => {
          return { label: country, value: country };
        });
        setCountries(options);
      })
      .catch((err) => {
        // notifyDanger(err.response.data.message || "some error occured");
      });
  };

  const getEnrollFilterOptions = () => {
    const url = "/user/enroll-filters";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        const batches = data.allBatches.map((date) => {
          return { batch: date, isCheck: false };
        });
        setBatches(batches);
        const options = data.allcountries.map((country) => {
          return { label: country, value: country };
        });

        setCountries(options);
      })
      .catch((err) => {
        // notifyDanger(err.response.data.message || "some error occured");
      });
  };

  const getInvoiceFilterOptions = () => {
    const url = "/user/enroll-filters";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        const batches = data.allBatches.map((date) => {
          return { batch: date, isCheck: false };
        });
        setBatches(batches);
        const options = data.allcountries.map((country) => {
          return { label: country, value: country };
        });

        setCountries(options);
      })
      .catch((err) => {
        // notifyDanger(err.response.data.message || "some error occured");
      });
  };

  const getContactUsFilterOptions = () => {
    const url = "/contactus/filters";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;

        setBatches([]);
        const options = data.allcountries.map((country) => {
          return { label: country, value: country };
        });

        setCountries(options);
      })
      .catch((err) => {
        // notifyDanger(err.response.data.message || "some error occured");
      });
  };

  const getBrochureFilters = () => {
    const url = "/brosureusers/filters";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;

        setBatches([]);
        const options = data.allcountries.map((country) => {
          return { label: country, value: country };
        });

        setCountries(options);
      })
      .catch((err) => {
        // notifyDanger(err.response.data.message || "some error occured");
      });
  };

  useEffect(() => {
    pathname === "/forms/payment-data" && getPaymentFilterOptions();
    pathname === "/forms/enroll-data" && getEnrollFilterOptions();
    pathname === "/forms/invoice-data" && getInvoiceFilterOptions();
    pathname === "/forms/contactus-data" && getContactUsFilterOptions();
    pathname === "/forms/brochure-data" && getBrochureFilters();

    clearAllData();
  }, [pathname]);

  const ApplyFilter = () => {
    const filterDate = batches
      .filter((date) => date.isCheck === true)
      .map((date) => {
        return date.batch;
      });

    let data = {
      selectedCohort: filterDate,
      country: selectedCountry.value,
      course: selectedCourse,
      program: selectedCourse,
      date: filterDate,
    };

    // searchTerm, program, date , startDate , endDate  , country
    if (isCalendar) {
      data.startDate = selectedDate[0].startDate;
      data.endDate = selectedDate[0].endDate;
    }
    if (pathname === "/forms/enquiry-data") {
      const modifiedArr = selectedCourse.map((data) => {
        return data.split(" ").join("-").toLowerCase() + "-program";
      });
      data.course = modifiedArr;
    }

    if (
      (pathname === "/forms/brochure-data" ||
        pathname === "/forms/payment-data") &&
      isFilterCheck
    ) {
      data.enroll_filter = isFilterCheck;
    }

    setFilterOptions(data);
  };

  const clearAllData = () => {
    setSelectedDate([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setIsBatch(false);
    setSelectedCountry("");
    setSelectedCourse([]);
    let filterBatch = batches.map((data) => {
      return { ...data, isCheck: false };
    });
    setBatches(filterBatch);
    setIsClearable(false);
    setFilterOptions({});
    setIsFilterCheck(false);
    setSelectedBatch([]);
  };

  console.log(selectedBatch);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={isFilterOpen ? { opacity: 1 } : { opacity: 0 }}
      transition={{ duration: 0.5 }}
      className={`${
        isFilterOpen ? "" : "filter_options_container_hide"
      } filter_options_container scrollbar-template`}
      ref={filterRef}
    >
      <IoMdClose
        className="filter_options_close"
        onClick={() => setIsFilterOpen(false)}
      />
      <div className="filter_options_heading">
        <h3>Filter</h3>
      </div>
      <div className="filter_options_div">
        <div className="filter_options_labels">
          <h4>Date Range</h4>
        </div>
        <div
          className="filter_options_inp cursor-pointer"
          onClick={() => {
            setIsCalendar((p) => !p);
            setSelectedDate([
              {
                startDate: new Date(),
                endDate: new Date(),
                key: "selection",
              },
            ]);
          }}
        >
          <span>DD/MM/YYYY</span>
          <span>
            <CiCalendar />
          </span>
        </div>
        {isCalendar && (
          <div className="filter_options_calendar">
            <DateRange ranges={selectedDate} onChange={handleSelectDate} />
          </div>
        )}
      </div>

      {(pathname === "/forms/payment-data" ||
        pathname === "/forms/enroll-data" ||
        pathname === "/forms/invoice-data") && (
        <div className="filter_options_div">
          <h4 htmlFor="">Batch</h4>
          <div
            className="filter_options_labels filter_options_batch_label"
            onClick={() => setIsBatch((p) => !p)}
          >
            <label>Batch</label>
            <TfiAngleDown />
          </div>
          {isBatch && (
            <div className="filter_options_batches scrollbar-template">
              {batches.map((data, i) => {
                return (
                  <BatchesItem
                    setSelectedBatch={setSelectedBatch}
                    selectedBatch={selectedBatch}
                    data={data}
                    i={i}
                    batches={batches}
                    pathname={pathname}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}
      {selectedBatch.length > 0 && (
        <div className="filter_batch_list">
          {selectedBatch.map((date) => {
            if (
              pathname === "/forms/enroll-data" ||
              pathname === "/forms/invoice-data"
            ) {
              return <div className="filter_batch_list_item">{date}</div>;
            } else {
              return (
                <div className="filter_batch_list_item">
                  {moment(date.date).format(`DD MMM YYYY [(${date.week})]`)}
                </div>
              );
            }
          })}
        </div>
      )}

      {(pathname === "/forms/payment-data" ||
        pathname === "/forms/enroll-data" ||
        pathname === "/forms/invoice-data" ||
        pathname === "/forms/contactus-data" ||
        pathname === "/forms/brochure-data") && (
        <div className="filter_options_div">
          <div className="filter_options_labels">
            <h4>Country</h4>
          </div>
          <ReactSelect
            value={selectedCountry}
            options={countries}
            isClearable={isClearable}
            onChange={(e) => {
              setSelectedCountry(e);
            }}
          />
        </div>
      )}

      {(pathname === "/forms/brochure-data" ||
        pathname === "/forms/payment-data") && (
        <div className="filter_radio_div">
          <input
            type="checkbox"
            checked={isFilterCheck}
            onChange={() => setIsFilterCheck((p) => !p)}
          />
          <label>Remove email’s with offer letter sent.</label>
        </div>
      )}

      {(pathname === "/forms/payment-data" ||
        pathname === "/forms/enroll-data" ||
        pathname === "/forms/invoice-data" ||
        pathname === "/forms/enquiry-data" ||
        pathname === "/forms/brochure-data") && (
        <div className="filter_options_div">
          <div className="filter_options_labels">
            <h4>Domain</h4>
          </div>
          <div className="filter_options_course">
            {Dummy_Data.map((data, i) => {
              return (
                <div
                  className={`${
                    selectedCourse.includes(data.course) ? "active" : ""
                  } filter_options_course_item`}
                  key={i}
                  onClick={() => {
                    if (selectedCourse.includes(data.course)) {
                      const filterArr = selectedCourse.filter(
                        (country) => country !== data.course
                      );
                      setSelectedCourse(filterArr);
                    } else {
                      setSelectedCourse([...selectedCourse, data.course]);
                    }
                  }}
                >
                  <span>{data.course}</span>
                  <IoMdClose />
                </div>
              );
            })}
          </div>
        </div>
      )}

      <div className="filter_apply_btn">
        <button onClick={clearAllData}>Clear All </button>
        <button onClick={() => ApplyFilter()}>Apply Button</button>
      </div>
    </motion.div>
  );
};

const BatchesItem = ({
  data,
  i,
  batches,
  pathname,
  setSelectedBatch,
  selectedBatch,
}) => {
  const [isCheck, setIsCheck] = useState(data.isCheck);
  return (
    <div className="filter_options_batch_item" key={i}>
      <input
        type="checkbox"
        checked={isCheck}
        key={i}
        onChange={() => {
          if (data.isCheck) {
            batches[i].isCheck = false;
            setIsCheck(false);
            setSelectedBatch(
              selectedBatch.filter((item) => item !== batches[i].batch)
            );
          } else {
            batches[i].isCheck = true;
            setIsCheck(true);
            setSelectedBatch([...selectedBatch, batches[i].batch]);
          }
        }}
      />
      <label>
        {pathname === "/forms/payment-data" &&
          moment(data.batch.date).format(`DD MMM YYYY [(${data.batch.week})]`)}
        {(pathname === "/forms/enroll-data" ||
          pathname === "/forms/invoice-data") &&
          data.batch}
      </label>
    </div>
  );
};

// filter_apply_btn .js
