// import axios from "axios";
import axios from "../axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import EmailHeader from "../components/Emailchats/EmailHeader";
import EmailChatsContent from "../components/Emailchats/EmailChatsContent";

const EmailChatDetail = () => {
  const { id } = useParams();
  const [emailContent, setEmailContent] = useState([]);

  const getEmail = () => {
    const url = `leads/get-email/${id}`;

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        console.log(data);
        setEmailContent(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEmail();
  }, [id]);


  return (
    <>
      <LayoutWrapper>
        {/* <Header /> */}
        <EmailHeader />
        <EmailChatsContent emailContent={emailContent} />
      </LayoutWrapper>
    </>
  );
};

export default EmailChatDetail;
