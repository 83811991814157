import React from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import MyleadTable from "../components/leadTables/MyleadTable";
import { useLocation } from "react-router-dom";
import DisputedTable from "../components/leadTables/DisputedTable";

const LeadStudent = () => {
  const { pathname } = useLocation();

  return (
    <LayoutWrapper>
      {/* <Header /> */}
      {pathname === "/leads/mylead" && <MyleadTable />}
      {pathname === "/leads/disputed-leads" && <DisputedTable />}
    </LayoutWrapper>
  );
};

export default LeadStudent;
