import React, { useEffect, useState } from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import EmailHeader from "../components/Emailchats/EmailHeader";
import EmailChatsList from "../components/Emailchats/EmailChatsList";
import EmailChatsContent from "../components/Emailchats/EmailChatsContent";

const Email = () => {

  const [searchQuery, setSearchQuery] = useState("");
  // const [isUpdate, setIsUpdate] = useState(false);
  // useEffect(() => {
  //   setIsUpdate((p) => !p);
  // }, [isUpdate]);

  // const [emailChats, setEmailChats] = useState([]);
  // const filterEmailChats = emailChats.filter((item) =>
  //   item.data.toLowerCase().includes(searchQuery.toLowerCase())
  // );

  // useEffect(() => {
  //   filterEmailChats();
  // }, []);
  // console.log(filterEmailChats)



  return (
    <>
      <LayoutWrapper>
        <div className="email_container">
          {/* <Header /> */}
          <EmailHeader
            // setIsUpdate={setIsUpdate}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
          <EmailChatsList searchQuery={searchQuery} />
          {/* <EmailChatsContent /> */}
        </div>
      </LayoutWrapper>
    </>
  );
};

export default Email;
