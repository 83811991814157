import React, { useEffect, useState } from "react";
import axios from "../../axios";
import moment from "moment/moment";
import Loader from "../../utils/Loader";
import EvidenceModal from "../FormStudent/EvidenceModal";
import { useSelector } from "react-redux";
import { NavLink } from "react-bootstrap";
import { confirmToast, notifySuccess } from "../../actions/customFn";
import { CiSearch } from "react-icons/ci";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import searchImage from "../../asset/images/search.png";

const DisputedTable = () => {
  const DUMMY_DATA = [
    {
      name: "Test User",
      email: "test@example.com",
      phone: "+92 1234567890",
    },
    {
      name: "Test User 2",
      email: "test2@example.com",
      phone: "+92 1234567890",
    },
    {
      name: "Test User 3",
      email: "test3@example.com",
      phone: "+92 1234567890",
    },
  ];

  const [leadData, setLeadData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [searchInp, setSearchInp] = useState("");
  const [isHover, setIsHover] = useState(false);

  const navigate = useNavigate();

  //

  useEffect(() => {
    setIsLoading(true);
    const url = "leads/myleads";
    let paramObj = { disputed: true };
    const config = {
      params: paramObj,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    if (searchInp !== "") {
      paramObj.searchTerm = searchInp.trim();
    }
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setLeadData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [isUpdate]);

  // entire
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setIsUpdate((p) => !p);
    }
  };

  const handleChange = (e) => {
    setSearchInp(e.target.value);
  };

  return (
    <>
      <div className="lead_table_searchbar">
        <div className="form_header">
          <BiChevronLeft onClick={() => navigate(-1)} size={35} />
          <h2>Disputed Lead</h2>
        </div>
        {/* <div className="form_table_nav_input">
          <CiSearch
            onClick={() => setIsUpdate((p) => !p)}
            className="form_table_nav_input_search"
          />
          <input
            type="text"
            placeholder="Enter email or name"
            value={searchInp}
            onChange={(e) => setSearchInp(e.target.value)}
          />
   
        </div> */}
        <div
          className={`offer_letter_table_head_search_icon ${
            isHover ? "expanded" : ""
          }`}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(true)}
        >
          <img src={searchImage} alt="" className="oflettersearch" />
          {isHover && (
            <>
              <input
                value={searchInp}
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                type="text"
                placeholder="Search here..."
              />
              <img
                src={searchImage}
                onClick={() => setIsUpdate((p) => !p)}
                alt=""
                className="search_icon_svg"
              />
            </>
          )}
        </div>
      </div>

      <div className="module_student_table table-responsive scrollbar-template lead_table_container">
        {isLoading ? (
          <Loader Height={"40vh"} />
        ) : (
          <table className="table-template-style dispute-table-div ">
            <thead>
              <tr>
                <th scope="col" className="table-first-element">
                  Sr no.
                </th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone no.</th>
                <th scope="col">Domain</th>
                <th scope="col">Country</th>
                <th scope="col">Status</th>
                <th scope="col">Uploded Files</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {leadData.map((data, i) => {
                return (
                  <TableItem
                    data={data}
                    index={i}
                    setIsLoading={setIsLoading}
                    setIsUpdate={setIsUpdate}
                  />
                );
              })}
            </tbody>
          </table>
        )}

        {/* <h4 className="text-center text-black mt-4">No Students Found!</h4> */}
      </div>
      {/* {!isLoading && (
        <div className="react_paginate_Div">
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={totalPages}
            previousLabel="< previous"
            activeClassName={"active"}
            renderOnZeroPageCount={null}
            forcePage={currentPage !== 1 ? currentPage - 1 : ""}
          />
        </div>
      )} */}
    </>
  );
};

export default DisputedTable;

const TableItem = ({ index, data, setIsLoading, setIsUpdate }) => {
  const [modalShow, setShowModal] = useState(false);
  const profileData = useSelector((state) => state.profileData.userData);
  const [statusInfo, setStatusInfo] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState(null);
  useEffect(() => {
    if (profileData) {
      const statusObj = data.claimedUsers.find(
        (val) => val.userid === profileData._id
      );

      const files = data.files.find((val) => val.userid === profileData._id);

      setStatusInfo(statusObj);
      setUploadedFiles(files);
    }
  }, [profileData, data]);

  const withdrawLead = () => {
    const url = "leads/withdraw-lead";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    const formData = {
      id: data._id,
    };
    axios
      .put(url, formData, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setIsUpdate((p) => !p);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{data.username}</td>
        <td>{data.email}</td>
        <td>{data.contact_no}</td>
        <td>{data.program_name}</td>
        <td>{data.country}</td>
        <td className="dispute-status">
          {statusInfo.status === "Pending" && (
            <button onClick={() => setShowModal(true)}>Upload</button>
          )}
          {statusInfo.status === "Won" && (
            <button className="dispute-status-won">Won</button>
          )}
          {statusInfo.status === "Lose" && (
            <button className="dispute-status-lost">Lost</button>
          )}
          {statusInfo.status === "Shared" && (
            <button className="dispute-status-shared">Shared</button>
          )}
        </td>
        <td className="d-flex " style={{ gap: "8px" }}>
          {uploadedFiles?.files?.map((file, index) => {
            return (
              <NavLink
                target="_blank"
                href={file}
                key={index}
                style={{ color: "#024c87" }}
              >
                file{index + 1},
              </NavLink>
            );
          }) || <h6 className="text-center">No Files Uploaded!</h6>}
        </td>
        <td>
          <button
            className="lead-withdraw-btn"
            onClick={() =>
              confirmToast(
                "Are you want to withdraw fron this lead?",
                withdrawLead
              )
            }
          >
            Withdraw
          </button>
        </td>
      </tr>
      {modalShow && (
        <EvidenceModal
          modalShow={modalShow}
          setShowModal={setShowModal}
          userData={{ email: data.email }}
          setIsLoading={setIsLoading}
          setIsUpdate={setIsUpdate}
        />
      )}
    </>
  );
};
