import React, { useState } from "react";
import { BiChevronLeft, BiSearch } from "react-icons/bi";
import { BsFilter } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import searchImage from "../../asset/images/search.png";

const EmailHeader = ({ searchQuery, setSearchQuery }) => {
  // how to get path name
  // const [isHover, setIsHover] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  // const handleKeyDown = (e) => {
  //   if (e.key === "Enter") {
  //     setIsUpdate((p) => !p);
  //   }
  // };

  // const handleChange = (e) => {
  //   setSearchQuery(e.target.value);
  // };

  //

  return (
    <>
      <div className="email_header">
        <h2>
          {location.pathname === "/email" ? (
            ""
          ) : (
            <BiChevronLeft
              onClick={() => navigate(-1)}
              size={34}
              style={{ cursor: "pointer" }}
            />
          )}
          Email
        </h2>
        {location.pathname === "/email" && (
          <div className="email_header_right_side">
            <div className="email_header_search_input">
              <BiSearch size={20} className="search_icon" />
              <input
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                type="text"
                placeholder="Search"
              />
            </div>
            {/* <div
              className={`offer_letter_table_head_search_icon ${
                isHover ? "expanded" : ""
              }`}
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(true)}
            >
              <img src={searchImage} alt="" className="oflettersearch" />
              {isHover && (
                <>
                  <input
                    value={searchQuery}
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                    type="text"
                    placeholder="Search here..."
                  />
                  <img
                    src={searchImage}
                    onClick={() => setIsUpdate((p) => !p)}
                    alt=""
                    className="search_icon_svg"
                  />
                </>
              )}
            </div> */}
            <div className="email_header_btn">
              <BsFilter size={22} />
            </div>
          </div>
        )}
      </div>

      {/*  */}
    </>
  );
};

export default EmailHeader;
