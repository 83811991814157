import React, { useEffect, useState } from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import ResourcesNav from "../components/resources/ResourcesNav";
import ResourcesFile from "../components/resources/ResourcesFile";
import { notifyDanger } from "../actions/customFn";
import axios from "../axios";

const Resources = () => {
  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [folderArr, setFolderArr] = useState([]);

  const getMyResources = () => {
    setIsLoading(true);
    const url = "/sales/my-resources";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        console.log(data);
        setIsLoading(false);
        setFolderArr(data);
      })
      .catch((err) => {
        notifyDanger(err?.response?.data?.message || "Some Error Occured");
        console.log(err);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getMyResources();
  }, [isUpdate]);

  return (
    <>
      <LayoutWrapper>
        {/* <Header /> */}
        <div className="resources_container" style={{ height: "100vh" }}>
          <ResourcesNav />
          <ResourcesFile folderArr={folderArr} setIsUpdate={setIsUpdate} />
        </div>
      </LayoutWrapper>
    </>
  );
};

export default Resources;
