import React, { useState, useEffect, useRef, useContext } from "react";
import {
  BiChevronDown,
  BiChevronLeft,
  BiEdit,
  BiSearch,
  BiTrash,
} from "react-icons/bi";
import { images, useOutsideClick } from "../../actions/customFn";
import ReactPaginate from "react-paginate";
import { CgArrowLongLeft, CgArrowLongRight } from "react-icons/cg";
import axios from "../../axios";
import moment from "moment";
import Loader from "../../utils/Loader";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { CiCalendar, CiFilter } from "react-icons/ci";
import { IoFilter, IoFilterSharp } from "react-icons/io5";
import { motion } from "framer-motion";
import filter from "../../asset/images/filter.png";
import filter_lines from "../../asset/images/filter-lines.png";
import searchImage from "../../asset/images/search.png";
import { EnrollContext } from "../../pages/EnrollStudent";
import { IoMdClose } from "react-icons/io";
import { DateRange } from "react-date-range";
import { TfiAngleDown } from "react-icons/tfi";
import ReactSelect from "react-select";

const EnrollStudentTable = ({
  rowsLimit,
  setRowsLimit,
  isUpdate,
  setIsUpdate,
}) => {
  //start

  const [studentList, setStudentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState(true);
  const [isRowOpen, setIsRowOpen] = useState(false);
  const handlePageClicking = (data) => {
    setPageNumber(data.selected);
  };
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSearch(true);
  };
  const handleSearchChange = (e) => {
    setSearch(false);
  };

  const navigate = useNavigate();

  const [searchToggle, setSearchToggle] = useState(false);
  const [buttonToggle, setButtonToggle] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInp, setSearchInp] = useState("");
  const [enrollList, setEnrollList] = useState([]);
  // const [isUpdate, setIsUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const { filterOptions } = useContext(EnrollContext);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  useEffect(() => {
    setIsLoading(true);
    const url = `/user/enroll-users`;

    let paramObj = {
      page: currentPage,
      limit: 20,
    };

    if (searchInp !== "") {
      paramObj.searchTerm = searchInp.trim();
    }

    if (rowsLimit) {
      paramObj.limit = rowsLimit;
    } else {
      paramObj.limit = 10;
    }

    // if (selectedDate !== "") {
    //   paramObj.date = selectedDate;
    // }
    // if (selectedProgram !== "") {
    //   paramObj.program = selectedProgram;
    // }

    paramObj = { ...paramObj, ...filterOptions };
    const config = {
      params: paramObj,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data, totalPages, totalCount } = res.data;
        setTotalPages(totalPages);
        setEnrollList(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err.response);
      });
    console.log("upadte");
  }, [currentPage, isUpdate]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setIsUpdate((p) => !p);
    }
  };

  const handleChange = (e) => {
    setSearchInp(e.target.value);
  };

  //

  return (
    <>
      <div className="offer_letter_table_head">
        <h2>
          <BiChevronLeft size={35} onClick={() => navigate(-1)} />
          Enrolled Students
        </h2>
        <div className="offer_letter_table_head_icon_div">
          <div
            className={`offer_letter_table_head_search_icon ${
              isHover ? "expanded" : ""
            }`}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(true)}
          >
            <img src={searchImage} alt="" className="oflettersearch" />
            {isHover && (
              <>
                <input
                  value={searchInp}
                  onKeyDown={handleKeyDown}
                  onChange={handleChange}
                  type="text"
                  placeholder="Search here..."
                />
                <img
                  src={searchImage}
                  onClick={() => setIsUpdate((p) => !p)}
                  alt=""
                  className="search_icon_svg"
                />
              </>
            )}
          </div>

          <div className="offer_letter_table_head_icon">
            <img onClick={() => setIsFilterOpen(true)} src={filter} alt="" />
          </div>
          <div
            onClick={() => setIsRowOpen(!isRowOpen)}
            className="offer_letter_table_head_icon"
          >
            <img src={filter_lines} alt="" />
            {isRowOpen && (
              <div className="rows_dropdown_list">
                <span
                  className={`${rowsLimit === "20" && "active"}`}
                  onClick={() => setRowsLimit("20")}
                >
                  <input
                    type="radio"
                    value="20"
                    name="rows"
                    checked={rowsLimit === "20"}
                    readOnly
                  />
                  <label>20 Rows</label>
                </span>
                <span
                  onClick={() => setRowsLimit("50")}
                  className={`${rowsLimit === "50" && "active"}`}
                >
                  <input
                    type="radio"
                    value="50"
                    name="rows"
                    checked={rowsLimit === "50"}
                    readOnly
                  />
                  <label>50 Rows</label>
                </span>
                <span
                  onClick={() => setRowsLimit("100")}
                  className={`${rowsLimit === "100" && "active"}`}
                >
                  <input
                    type="radio"
                    value="100"
                    name="rows"
                    checked={rowsLimit === "100"}
                    readOnly
                  />
                  <label>100 Rows</label>
                </span>
              </div>
            )}
          </div>
          <button
            className="offer_letter_table_head_btn"
            onClick={() => setButtonToggle(!buttonToggle)}
          >
            Add New <BiChevronDown size={26} />
            {buttonToggle && (
              <div className="offer_letter_table_dropdown">
                <span>
                  Multiple Contact
                  <input type="checkbox" />
                </span>
                <span>
                  Upload File
                  <input type="checkbox" />
                </span>
              </div>
            )}
          </button>
        </div>

        <FilterOption
          isFilterOpen={isFilterOpen}
          setIsFilterOpen={setIsFilterOpen}
        />
      </div>

      {isLoading ? (
        <Loader Height={"50vh"} />
      ) : (
        <div className="offer_letter_table_container scrollbar-template">
          <div className="offer_letter_table_div">
            <table>
              <thead>
                <tr>
                  <th className="checkbox_input">
                    {/* <input type="checkbox" /> */}
                    Sr No.
                  </th>
                  <th>Name</th>
                  <th>Email address</th>
                  <th>Phone Number</th>
                  <th>Domain</th>
                  <th>Created At</th>
                  <th>Country</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {enrollList?.length > 0 &&
                  enrollList.map((data, i) => {
                    return (
                      <tr>
                        <td className="checkbox_input">{i + 1}</td>
                        <td>
                          <div className="offer_letter_table_name">
                            {/* <img src={images["offer_letter_data_name.png"]} alt="" /> */}
                            <NavLink to={`/enroll-student-detail/${data._id}`}>
                              <span>
                                <h3>{data.username}</h3>
                              </span>
                            </NavLink>
                          </div>
                        </td>
                        <td>{data.email}</td>
                        <td>{data.contact_no}</td>
                        <td>{data.program_name}</td>
                        <td>
                          {moment(data.createdAt).format("DD MMM YYYY LT")}
                        </td>
                        <td>{data.country}</td>
                        {/* <td>
                        <BiTrash size={22} />
                      </td> */}
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            <div className="operation_offer_paginate offer_letter_table_paginate ">
              <ReactPaginate
                breakLabel="..."
                pageCount={totalPages}
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                activeClassName={"active"}
                forcePage={currentPage !== 1 ? currentPage - 1 : ""}
                previousLabel={
                  <span className="prev-button">
                    <CgArrowLongLeft />
                    Previous
                  </span>
                }
                nextLabel={
                  <span className="next-button">
                    Next
                    <CgArrowLongRight />
                  </span>
                }
              />
            </div>
          </div>
        </div>
      )}

      {/* operation_offer_paginate offer_letter_table_paginate .offer_letter_paginate_table */}
    </>
  );
};

export default EnrollStudentTable;

const Dummy_Data = [
  {
    course: "Data Science",
  },
  {
    course: "Digital Marketing",
  },
  {
    course: "Cyber Security",
  },
  {
    course: "Business Analytics",
  },
  {
    course: "Data Analytics",
  },
  {
    course: "Data Engineering",
  },
  {
    course: "Project Management",
  },
  {
    course: "DevOps Engineer",
  },
];

const FilterOption = ({ isFilterOpen, setIsFilterOpen }) => {
  const [selectedDate, setSelectedDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [isClearable, setIsClearable] = useState(false);

  const handleSelectDate = (ranges) => {
    // setSelectedDate([ranges.selection]);
    const adjustedEndDate = new Date(ranges.selection.endDate);
    adjustedEndDate.setHours(23, 59, 59, 999);
    setSelectedDate([
      {
        ...ranges.selection,
        endDate: adjustedEndDate,
      },
    ]);
  };

  const [isCalendar, setIsCalendar] = useState(false);
  const filterRef = useRef(null);
  useOutsideClick(filterRef, setIsFilterOpen);
  const [batches, setBatches] = useState([]);

  const [countries, setCountries] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);

  const [isBatch, setIsBatch] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState("");
  const { pathname } = useLocation();

  const [isFilterCheck, setIsFilterCheck] = useState(false);

  const { setFilterOptions } = useContext(EnrollContext);

  const getPaymentFilterOptions = () => {
    const url = "/payment/filter-options";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        const batches = data.allBatches.map((date) => {
          return { batch: date, isCheck: false };
        });
        setBatches(batches);
        const options = data.allcountries.map((country) => {
          return { label: country, value: country };
        });
        setCountries(options);
      })
      .catch((err) => {
        // notifyDanger(err.response.data.message || "some error occured");
      });
  };

  const getEnrollFilterOptions = () => {
    const url = "/user/enroll-filters";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        const batches = data.allBatches.map((date) => {
          return { batch: date, isCheck: false };
        });
        setBatches(batches);
        const options = data.allcountries.map((country) => {
          return { label: country, value: country };
        });

        setCountries(options);
      })
      .catch((err) => {
        // notifyDanger(err.response.data.message || "some error occured");
      });
  };

  const getInvoiceFilterOptions = () => {
    const url = "/user/enroll-filters";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        const batches = data.allBatches.map((date) => {
          return { batch: date, isCheck: false };
        });
        setBatches(batches);
        const options = data.allcountries.map((country) => {
          return { label: country, value: country };
        });

        setCountries(options);
      })
      .catch((err) => {
        // notifyDanger(err.response.data.message || "some error occured");
      });
  };

  const getContactUsFilterOptions = () => {
    const url = "/contactus/filters";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;

        setBatches([]);
        const options = data.allcountries.map((country) => {
          return { label: country, value: country };
        });

        setCountries(options);
      })
      .catch((err) => {
        // notifyDanger(err.response.data.message || "some error occured");
      });
  };

  const getBrochureFilters = () => {
    const url = "/brosureusers/filters";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;

        setBatches([]);
        const options = data.allcountries.map((country) => {
          return { label: country, value: country };
        });

        setCountries(options);
      })
      .catch((err) => {
        // notifyDanger(err.response.data.message || "some error occured");
      });
  };

  const getEnrollFilters = () => {
    const url = "/user/enroll-filters";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        const batches = data.allBatches.map((date) => {
          return { batch: date, isCheck: false };
        });
        setBatches(batches);
        const options = data.allcountries.map((country) => {
          return { label: country, value: country };
        });

        setCountries(options);
      })
      .catch((err) => {
        // notifyDanger(err.response.data.message || "some error occured");
      });
  };

  useEffect(() => {
    pathname === "/forms/payment-data" && getPaymentFilterOptions();
    pathname === "/forms/enroll-data" && getEnrollFilterOptions();
    pathname === "/forms/invoice-data" && getInvoiceFilterOptions();
    pathname === "/forms/contactus-data" && getContactUsFilterOptions();
    pathname === "/forms/brochure-data" && getBrochureFilters();
    pathname === "/enroll-student" && getEnrollFilters();
    clearAllData();
  }, [pathname]);

  const ApplyFilter = () => {
    const filterDate = batches
      .filter((date) => date.isCheck === true)
      .map((date) => {
        return date.batch;
      });

    let data = {
      selectedCohort: filterDate,
      country: selectedCountry.value,
      course: selectedCourse,
      program: selectedCourse,
      date: filterDate,
    };
    // console.log(data)

    // searchTerm, program, date , startDate , endDate  , country
    if (isCalendar) {
      data.startDate = selectedDate[0].startDate;
      data.endDate = selectedDate[0].endDate;
    }
    if (pathname === "/forms/enquiry-data") {
      const modifiedArr = selectedCourse.map((data) => {
        return data.split(" ").join("-").toLowerCase() + "-program";
      });
      data.course = modifiedArr;
    }

    if (
      (pathname === "/forms/brochure-data" ||
        pathname === "/forms/payment-data") &&
      isFilterCheck
    ) {
      data.enroll_filter = isFilterCheck;
    }

    setFilterOptions(data);
  };

  const clearAllData = () => {
    setSelectedDate([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setIsBatch(false);
    setSelectedCountry("");
    setSelectedCourse([]);
    let filterBatch = batches.map((data) => {
      return { ...data, isCheck: false };
    });
    setBatches(filterBatch);
    setIsClearable(false);
    setFilterOptions({});
    setIsFilterCheck(false);
    setSelectedBatch([]);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={isFilterOpen ? { opacity: 1 } : { opacity: 0 }}
      transition={{ duration: 0.5 }}
      className={`${
        isFilterOpen ? "" : "filter_options_container_hide"
      } filter_options_container scrollbar-template`}
      ref={filterRef}
    >
      <IoMdClose
        className="filter_options_close"
        onClick={() => setIsFilterOpen(false)}
      />
      <div className="filter_options_heading">
        <h3>Filter</h3>
      </div>
      <div className="filter_options_div">
        <div className="filter_options_labels">
          <h4>Date Range</h4>
        </div>
        <div
          className="filter_options_inp cursor-pointer"
          onClick={() => {
            setIsCalendar((p) => !p);
            setSelectedDate([
              {
                startDate: new Date(),
                endDate: new Date(),
                key: "selection",
              },
            ]);
          }}
        >
          <span>DD/MM/YYYY</span>
          <span>
            <CiCalendar />
          </span>
        </div>
        {isCalendar && (
          <div className="filter_options_calendar">
            <DateRange ranges={selectedDate} onChange={handleSelectDate} />
          </div>
        )}
      </div>

      {(pathname === "/forms/payment-data" ||
        pathname === "/forms/enroll-data" ||
        pathname === "/enroll-student" ||
        pathname === "/forms/invoice-data") && (
        <div className="filter_options_div">
          <h4>Batch</h4>
          <div
            className="filter_options_labels filter_options_batch_label"
            onClick={() => setIsBatch((p) => !p)}
          >
            <label>Batch</label>
            <TfiAngleDown />
          </div>
          {isBatch && (
            <div className="filter_options_batches scrollbar-template">
              {batches.map((data, i) => {
                return (
                  <BatchesItem
                    setSelectedBatch={setSelectedBatch}
                    selectedBatch={selectedBatch}
                    data={data}
                    i={i}
                    batches={batches}
                    pathname={pathname}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}
      {selectedBatch.length > 0 && (
        <div className="filter_batch_list">
          {selectedBatch.map((date) => {
            if (
              pathname === "/forms/enroll-data" ||
              pathname === "/enroll-student" ||
              pathname === "/forms/invoice-data"
            ) {
              return <div className="filter_batch_list_item">{date}</div>;
            } else {
              return (
                <div className="filter_batch_list_item">
                  {moment(date.date).format(`DD MMM YYYY [(${date.week})]`)}
                </div>
              );
            }
          })}
        </div>
      )}

      {(pathname === "/forms/payment-data" ||
        pathname === "/forms/enroll-data" ||
        pathname === "/forms/invoice-data" ||
        pathname === "/enroll-student" ||
        pathname === "/forms/contactus-data" ||
        pathname === "/forms/brochure-data") && (
        <div className="filter_options_div">
          <div className="filter_options_labels">
            <h4>Country</h4>
          </div>
          <ReactSelect
            value={selectedCountry}
            options={countries}
            isClearable={isClearable}
            onChange={(e) => {
              setSelectedCountry(e);
            }}
          />
        </div>
      )}

      {(pathname === "/forms/brochure-data" ||
        pathname === "/forms/payment-data") && (
        <div className="filter_radio_div">
          <input
            type="checkbox"
            checked={isFilterCheck}
            onChange={() => setIsFilterCheck((p) => !p)}
          />
          <label>Remove email’s with offer letter sent.</label>
        </div>
      )}

      {(pathname === "/forms/payment-data" ||
        pathname === "/forms/enroll-data" ||
        pathname === "/forms/invoice-data" ||
        pathname === "/enroll-student" ||
        pathname === "/forms/enquiry-data" ||
        pathname === "/forms/brochure-data") && (
        <div className="filter_options_div">
          <div className="filter_options_labels">
            <h4>Domain</h4>
          </div>
          <div className="filter_options_course">
            {Dummy_Data.map((data, i) => {
              return (
                <div
                  className={`${
                    selectedCourse.includes(data.course) ? "active" : ""
                  } filter_options_course_item`}
                  key={i}
                  onClick={() => {
                    if (selectedCourse.includes(data.course)) {
                      const filterArr = selectedCourse.filter(
                        (country) => country !== data.course
                      );
                      setSelectedCourse(filterArr);
                    } else {
                      setSelectedCourse([...selectedCourse, data.course]);
                    }
                  }}
                >
                  <span>{data.course}</span>
                  <IoMdClose />
                </div>
              );
            })}
          </div>
        </div>
      )}

      <div className="filter_apply_btn">
        <button onClick={clearAllData}>Clear All </button>
        <button onClick={() => ApplyFilter()}>Apply Button</button>
      </div>
    </motion.div>
  );
};

const BatchesItem = ({
  data,
  i,
  batches,
  pathname,
  setSelectedBatch,
  selectedBatch,
}) => {
  const [isCheck, setIsCheck] = useState(data.isCheck);
  return (
    <div className="filter_options_batch_item" key={i}>
      <input
        type="checkbox"
        checked={isCheck}
        key={i}
        onChange={() => {
          if (data.isCheck) {
            batches[i].isCheck = false;
            setIsCheck(false);
            setSelectedBatch(
              selectedBatch.filter((item) => item !== batches[i].batch)
            );
          } else {
            batches[i].isCheck = true;
            setIsCheck(true);
            setSelectedBatch([...selectedBatch, batches[i].batch]);
          }
        }}
      />
      <label>
        {pathname === "/forms/payment-data" &&
          moment(data.batch.date).format(`DD MMM YYYY [(${data.batch.week})]`)}
        {(pathname === "/forms/enroll-data" ||
          pathname === "/enroll-student" ||
          pathname === "/forms/invoice-data") &&
          data.batch}
      </label>
    </div>
  );
};
