import React, { useEffect, useRef, useState } from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import { FaChevronDown, FaChevronLeft, FaChevronUp } from "react-icons/fa6";
import { BiSearch, BiSolidMessage } from "react-icons/bi";
import { FiMessageSquare } from "react-icons/fi";
import axios from "../axios";
import { notifyDanger, notifySuccess } from "../actions/customFn";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import SmallLoader from "../utils/SmallLoader";
import { useNavigate, useParams } from "react-router-dom";

const TicketChats = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [ticketData, setTicketData] = useState([]);
  const profileData = useSelector((state) => state.profileData.userData);
  const [ticketId, setTicketId] = useState("");
  const [currentTicketData, setCurrentTicketData] = useState({});
  const [ticketInp, setTicketInp] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [statusInfo, setStatusInfo] = useState("");
  const [statusLoading, setStatusLoading] = useState(false);

  const [isChatUpdate, setIsChatUpdate] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isStatusUpdate, setIsStatusUpdate] = useState(false);
  const chatRef = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const handleEvent = () => {
    setIsOpen(!isOpen);
  };

  const getAllTickets = () => {
    const url = `/qna/all-tickets`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setTicketData(data);
      })
      .catch((err) => {
        console.log(err);
        notifyDanger(err.response.data.message || err.response);
      });
  };

  useEffect(() => {
    getAllTickets();
  }, [isChatUpdate]);

  const getTicketChat = () => {
    const url = `/qna/ticket/chat/${ticketId}`;

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setCurrentTicketData(data);
      })
      .catch((err) => {
        console.log(err);
        notifyDanger(err.response.data.message || err.response);
      });
  };

  const sendTicketChat = () => {
    setIsLoading(true);
    const url = `/qna/ticket/chat`;

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    const data = {
      userid: profileData._id,
      text: ticketInp,
      id: currentTicketData._id,
    };

    axios
      .put(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setIsLoading(false);
        setIsUpdate((p) => !p);
        setTicketInp("");
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        notifyDanger(err.response.data.message || err.response);
      });
  };

  // console.log(ticketData);

  useEffect(() => {
    if (ticketId) {
      getTicketChat();
    }
  }, [ticketId, isUpdate, isStatusUpdate]);

  const scrollToChat = () => {
    const ele = chatRef.current;
    if (ele) {
      ele.scrollTop = ele.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToChat();
  }, [currentTicketData?.chats]);

  useEffect(() => {
    setStatusInfo(currentTicketData.status);
  }, [currentTicketData.status]);

  const updateTicketStatus = () => {
    setStatusLoading(true);
    const url = `/qna/ticket/status`;

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    const data = {
      status: statusInfo,
      id: currentTicketData._id,
    };

    axios
      .put(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setStatusLoading(false);
        setIsStatusUpdate((p) => !p);
      })
      .catch((err) => {
        setIsLoading(false);
        setStatusLoading(false);
        console.log(err);
        notifyDanger(err.response.data.message || err.response);
      });
  };

  const filterData = ticketData.filter((item) =>
    item.query.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    if (id) {
      setTicketId(id);
    }
  }, [id]);

  console.log(currentTicketData);

  return (
    <>
      <LayoutWrapper>
        {/* <Header /> */}
        <div className="ticket_chats_container">
          <div className="ticket_chats_header">
            <h2>
              <FaChevronLeft
                size={18}
                onClick={() => navigate(-1)}
                style={{ cursor: "pointer" }}
              />
              Ticket Query
            </h2>
          </div>

          <div className="ticket_chats_div">
            <div className="ticket_chats_list scrollbar-hide">
              <div className="ticket_chats_list_header">
                <div className="ticket_chats_search_input">
                  <input
                    onChange={handleInputChange}
                    value={searchQuery}
                    type="search"
                    placeholder="Search"
                  />
                  <BiSearch className="search_icon" />
                </div>
              </div>
              {filterData.length > 0 ? (
                filterData.map((data, i) => {
                  return (
                    <TicketChatsItem
                      data={data}
                      currentTicketData={currentTicketData}
                      setTicketId={setTicketId}
                      setIsChatUpdate={setIsChatUpdate}
                    />
                  );
                })
              ) : (
                <p className="dashboard_ticket_chats_no_found">
                  No Chats Found
                </p>
              )}
            </div>

            {currentTicketData && Object.keys(currentTicketData).length > 0 && (
              <>
                <div className="ticket_chats_box">
                  <div className="ticket_chats_box_header">
                    <div className="ticket_chats_box_user_div">
                      {
                        currentTicketData?.raisedBy?.username
                          ?.split(" ")[0]
                          .split("")[0]
                      }
                    </div>
                    <BiSolidMessage style={{ marginRight: "5px" }} />
                    {currentTicketData?.raisedBy?.username}
                  </div>
                  <div className="ticket_chats_box_container">
                    <div
                      className="ticket_chats_box_chatlist scrollbar-hide  "
                      ref={chatRef}
                    >
                      {currentTicketData?.chats?.length > 0 &&
                        currentTicketData.chats.map((data, i) => {
                          if (profileData._id === data.userid) {
                            return (
                              <p>
                                {data.text}
                                <b>
                                  {moment(data.date).format("DD MMM YYYY LT")}
                                </b>
                              </p>
                            );
                          } else {
                            return (
                              <p className="ticket_chats_box_giver_reply">
                                {data.text}
                                <b>
                                  {moment(data.date).format("DD MMM YYYY LT")}
                                </b>
                              </p>
                            );
                          }
                        })}
                    </div>

                    {/* <div className="ticket_chats_box_receiver"> */}
                    {/* <div className="ticket_chats_box_receiver_profile">D</div> */}
                    {/* <div className="ticket_chats_box_receiver_message">
                    UI/UX Designer Needs...
                  </div> */}
                    {/* </div> */}

                    {/* <span>3 days ago</span> */}

                    {/* <div className="ticket_chats_box_receiver ticket_chats_box_giver"> */}
                    {/* <div className="ticket_chats_box_receiver_profile">D</div>
                  <div className="ticket_chats_box_receiver_message">
                    UI/UX Designer Needs...
                  </div> */}
                    {/* </div> */}

                    {/* <span className="ticket_chats_box_giver_last_seen">
                  3 days ago
                </span> */}

                    {currentTicketData.status === "closed" ? (
                      <>
                        <div className="ticket_chat_notallowed_divider"></div>
                        <h4 className="ticket_chat_notallowed">
                          You cannot chat with the user because the assigned
                          ticket has been closed.
                        </h4>
                      </>
                    ) : (
                      <div className="ticket_chat_box_textarea">
                        <textarea
                          value={ticketInp}
                          onChange={(e) => setTicketInp(e.target.value)}
                        />
                        <button
                          onClick={(e) => {
                            ticketInp.trim().length > 0 && sendTicketChat();
                          }}
                        >
                          {isLoading ? <SmallLoader color={"#fff"} /> : "Send"}
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="ticket_chats_user_details">
                  <div className="ticket_chats_user_details_header">
                    User Details
                  </div>
                  <div className="ticket_chats_user_details_div">
                    <div className="ticket_chats_user_contact_box">
                      <div className="contact_box_header">Contact Details</div>
                      <div className="ticket_chats_user_contact_box_content">
                        <span>
                          <h5>Name</h5>
                          <p>{currentTicketData?.raisedBy?.username}</p>
                        </span>
                        <span>
                          <h5>Email</h5>
                          <p>{currentTicketData?.raisedBy?.email}</p>
                        </span>
                        <span>
                          <h5>Program Enroll</h5>
                          <p>
                            {currentTicketData?.raisedBy?.batch_enroll?.map(
                              (val) => {
                                return val.courseInfo.title + " ";
                              }
                            )}
                          </p>
                        </span>
                        <span>
                          <h5>Phone</h5>
                          <h6>{currentTicketData?.raisedBy?.phone_no}</h6>
                        </span>
                      </div>
                    </div>

                    <div className="ticket_chats_user_ticket_dropdown">
                      <div className="ticket_chats_user_ticket_dropdown_header">
                        Tickets
                      </div>

                      <div className="ticket_chats_user_ticket_dropdown_content">
                        {!isOpen && (
                          <div className="ticket_chats_user_ticket_dropdown_item">
                            <h4>Status</h4>
                            <li
                              onClick={handleEvent}
                              className="cursor-pointer"
                            >
                              {currentTicketData.status} <FaChevronDown />
                            </li>
                          </div>
                        )}

                        {isOpen && (
                          <div
                            className={
                              "ticket_chats_user_ticket_dropdown_item_list"
                            }
                          >
                            <h4 onClick={() => setIsOpen(!isOpen)}>
                              Status <FaChevronUp />
                            </h4>
                            <li>
                              Pending
                              <input
                                type="checkbox"
                                checked={
                                  statusInfo === "pending" ? true : false
                                }
                                onChange={() => {
                                  statusInfo === "pending"
                                    ? setStatusInfo("")
                                    : setStatusInfo("pending");
                                }}
                              />
                            </li>
                            <li>
                              Closed{" "}
                              <input
                                type="checkbox"
                                checked={statusInfo === "closed" ? true : false}
                                onChange={() => {
                                  statusInfo === "closed"
                                    ? setStatusInfo("")
                                    : setStatusInfo("closed");
                                }}
                              />
                            </li>
                            <li>
                              Open{" "}
                              <input
                                type="checkbox"
                                checked={statusInfo === "open" ? true : false}
                                onChange={() => {
                                  statusInfo === "open"
                                    ? setStatusInfo("")
                                    : setStatusInfo("open");
                                }}
                              />
                            </li>

                            <button
                              disabled={statusLoading}
                              onClick={updateTicketStatus}
                            >
                              {statusLoading ? (
                                <SmallLoader color={"#fff"} />
                              ) : (
                                "Update"
                              )}
                            </button>
                          </div>
                        )}

                        {/* <div className="ticket_chats_user_ticket_dropdown_item">
                          <h4>Priority</h4>
                          <li>Medium</li>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </LayoutWrapper>
    </>
  );
};

export default TicketChats;

const TicketChatsItem = ({
  data,
  currentTicketData,
  setTicketId,
  setIsChatUpdate,
}) => {
  const [notification, setNotification] = useState(0);
  const profileData = useSelector((state) => state.profileData);
  const [notificationId, setNotificationId] = useState("");

  useEffect(() => {
    const currentNotification = data.notification.find(
      (val) => val.refId !== profileData._id
    );
    if (currentNotification) {
      setNotification(currentNotification.count);
      setNotificationId(currentNotification.refId);
    }
  }, [data]);

  const seenMsg = () => {
    const url = `/qna/ticket-seen`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    const formData = {
      id: data._id,
      notificationId: notificationId,
    };
    axios
      .put(url, formData, config)
      .then((res) => {
        const { message } = res.data;
        setIsChatUpdate((p) => !p);
      })
      .catch((err) => {
        console.log(err);
        notifyDanger(err.response.data.message || err.response);
      });
  };

  return (
    <div
      className={`ticket_chats_listitem cursor-pointer ${
        currentTicketData._id === data._id ? "active" : ""
      } `}
      onClick={() => {
        setTicketId(data._id);
        seenMsg();
      }}
    >
      <div className="ticket_chats_listitem_user">
        {data?.raisedBy?.username?.split(" ")[0].split("")[0]}
      </div>
      <div className="ticket_chats_listitem_user_chat_detail">
        <div className="ticket_chats_listitem_badge_div">
          {/* <span>Overdue by 3 days</span> */}
          {/* <span>Customer responded 1h ago</span>*/}
        </div>
        <p>
          {data.query}
          <span>#{data.ticketId}</span>
        </p>
        <div className="ticket_chats_listitem_author_name">
          <FiMessageSquare style={{ marginRight: "3px" }} />
          {data?.raisedBy?.username}
          {/* <div className="dot"></div> */}
          {/* Headboard Workshop */}
        </div>
      </div>

      {notification !== 0 && (
        <div className="ticket_chats_listitem_notification">{notification}</div>
      )}
    </div>
  );
};
